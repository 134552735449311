<template>
  <div>
    <picker
      set="emojione"
      title="Pick your emoji"
      emoji="point_up"
      @select="selectEmoji"
      :data="emojiIndex"
    />
  </div>
</template>

<script>
import data from 'emoji-mart-vue-fast/data/emojione.json'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import customEmojis from 'data/custom_emojis.json'

let emojisToShowFilter = function(emoji) {
  return true // return true to include or false to exclude
}

let include = []
let exclude = []

let custom = customEmojis
for (const key in custom) {
  // 画像をvueで使えるようにする
  // require()の引数を変数のみにしてしまうと、なぜか画像の読み込みエラーになるので、こうしている
  custom[key]["imageUrl"] = require(`images/main/emojis/${custom[key]["imageUrl"]}`)
}

let emojiIndex = new EmojiIndex(data, {
  emojisToShowFilter,
  include,
  exclude,
  custom,
})

export default {
  components: {
    'picker': Picker,
  },
  methods: {
    selectEmoji(emoji) {
      let emojiText = ""
      if (emoji.custom) {
        emojiText = emoji.colons + " "
      } else {
        emojiText = emoji.native
      }
      this.$emit("add-emoji-to-text", emojiText)
    }
  },
  data: function() {
    return {
      emojiIndex: emojiIndex,
    }
  }
}
</script>
